export const GTM_VARIABLES = {
  CLEARBIT_COMPANY_EMPLOYEE_RANGE: 'clearbit_company_employee_range',
  CLEARBIT_COMPANY_INDUSTRY: 'clearbit_company_industry',
  CLEARBIT_COMPANY_NAME: 'clearbit_company_name',
  CLEARBIT_COMPANY_REVENUE_RANGE: 'clearbit_company_revenue_range',
  CLEARBIT_COMPANY_SUBINDUSTRY: 'clearbit_company_subindustry',
  GOOGLE_CLIENT_ID: 'google_client_id',
  HUBSPOT_FORM_ID: 'hubspot_form_id',
  INITIAL_PAGE_VIEW: 'initial_page_view',
  OPTIMIZELY_VARIATION_IDS: 'optimizely_variation_ids',
  USER_EMAIL: 'email',
  USER_FIRST_NAME: 'first_name',
  USER_LAST_NAME: 'last_name',
  USER_PHONE: 'phone_number',
} as const;

export const GTM_EVENTS = {
  BLOG_SUBSCRIBE: 'blog_subscribe',
  DEMO_REQUEST: 'demo_request',
  GET_STARTED_FORM_SUBMIT_FAILED: 'get_started_modal_form_submit_failed',
  GET_STARTED_FORM_SUBMIT: 'get_started_modal_form_submit',
  GET_STARTED_MODAL_CLOSED: 'get_started_modal_closed',
  GET_STARTED_MODAL_OPENED: 'get_started_modal_opened',
  GET_STARTED_TRIAL_SIGNUP: 'get_started_modal_trial_signup',
  GET_STARTED_TRIAL_SUBMIT: 'get_started_modal_trial_submit',
  HUBSPOT_FORM_SUCCESS: 'hubspot-form-success',
  OPTIMIZELY_DECISION: 'optimizely_campaign_decision',
  PAGEVIEW: 'custom_page_view',
  RESOURCE_FORM_SUCCESS: 'resource_form_success',
  TRIAL_SIGNUP: 'trial_signup',
} as const;

const GA4_EVENT_NAMES = [
  'change_filter',
  'checked_filter',
  'click',
  'collapsed_category',
  'collapsed_menu',
  'customer_feedback',
  'download',
  'expanded_category',
  'expanded_menu',
  'free_trial_form',
  'generate_lead',
  'modal_closed',
  'modal_opened',
  'page_view',
  'play_audio',
  'play_video',
  'requested_demo',
  'scheduled_meeting',
  'search',
  'submit_form',
  'subscribed',
  'trial_signup',
  'unchecked_filter',
  'viewed_tour_step',
] as const;

export type GA4Events = (typeof GA4_EVENT_NAMES)[number];

const GA4_EVENT_PARAMETER_NAMES = [
  'audio_title',
  'audio_url',
  'category',
  'download_url',
  'element_label',
  'element_type',
  'location',
  'modal_label',
  'search_terms',
  'video_title',
  'video_url',
  'video_youtube_id',
] as const;

export type GA4EventParameters = {
  element_type?: 'button' | 'link';
} & {
  [key in (typeof GA4_EVENT_PARAMETER_NAMES)[number] | (string & {})]?: string;
} & {
  [key: string]: string;
};
